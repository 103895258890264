import Typography from '@bluheadless/ui/src/atoms/typography'
import BHLink from '@bluheadless/ui/src/molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '@bluheadless/ui/src/molecules/button'
import { OverlayContainer } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo', 'colorHex'].includes(prop)
}

const Subtitle = styled(Typography)`
	text-transform: uppercase;
	${({ theme }) => theme.typography.subheadline1};
`

const Title = styled(Typography)`
	${({ theme }) => theme.typography.headlinebig};
	font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	line-height: 23px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(32)};
	}
`

const HeroStyled = styled(Hero)`
	display: flex;
	align-items: center;
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	> a {
		${({ theme }) => theme.breakpoints.up('md')} {
			height: fit-content;
		}
		> div {
			${({ theme }) => theme.breakpoints.up('md')} {
				height: fit-content;
			}
		}
	}
	&.bottom {
		${({ theme }) => theme.breakpoints.down('md')} {
			align-items: center;
			text-align: left;
		}
	}
	&.top {
		${({ theme }) => theme.breakpoints.down('md')} {
			align-items: center;
			text-align: left;
		}
	}
	${OverlayContainer} {
		position: relative;
		margin: unset;
		width: unset;
		padding: ${({ theme }) => theme.spacing(1)};
		${({ theme }) => theme.breakpoints.up('md')} {
			bottom: inherit;
			padding: var(--size-big);
		}
	}
`

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: unset;
	${({ theme }) => theme.breakpoints.down('md')} {
		text-align: left;
	}
`

const Description = styled('div')`
	${({ theme }) => theme.typography.subheadline1};
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
	}
	.MuiButton-root.MuiButton-textPrimary {
		font-family: var(--font-garamond-italic);
		font-size: var(--size-biggest);
		color: var(--color-primary);
		border-color: var(--color-primary);
		border-width: 0;
		width: fit-content;
		display: block;
		min-height: unset;

		&::after {
			display: none;
		}
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton, { shouldForwardProp })`
	color: ${({ colorHex }) => colorHex};
	border-color: ${({ colorHex }) => colorHex};
	&:hover {
		color: ${({ colorHex }) => colorHex};
		border-color: ${({ colorHex }) => colorHex};
	}
	&.MuiButton-textPrimary {
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		margin: 0;
	}
`

export { HeroStyled, Subtitle, Container, Title, Description, CtaContainer, Link, Button }
